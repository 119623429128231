import dynamic from 'next/dynamic';

export default {
    ArticlePage: dynamic(() => import('./ArticlePage')),
    CampaignPage: dynamic(() => import('./CampaignPage')),
    DistrictPage: dynamic(() => import('./DistrictPage')),
    DocsPage: dynamic(() => import('./DocsPage')),
    GuidePage: dynamic(() => import('./GuidePage')),
    HomePage: dynamic(() => import('./HomePage')),
    HomeApiPage: dynamic(() => import('./HomeApiPage')),
    EventListPage: dynamic(() => import('./EventListPage')),
    NeighbourhoodPage: dynamic(() => import('./NeighbourhoodPage')),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    EventDetail: dynamic(() => import('./EventDetail')),
    CategoryPage: dynamic(() => import('./CategoryPage')),
    ObjectPage: dynamic(() => import('./ObjectPage')),
    LocalListPage: dynamic(() => import('./LocalListPage')),
    LocalProfilePage: dynamic(() => import('./LocalProfilePage')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
    MyListPage: dynamic(() => import('./MyListPage')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
    SurveyPage: dynamic(() => import('./SurveyPage')),
    WindowPage: dynamic(() => import('./WindowPage')),
    SearchPage: dynamic(() => import('./SearchPage')),
    EventSubmissionPage: dynamic(() => import('./EventSubmissionPage')),
    SnippetPreview: dynamic(() => import('./SnippetPreview')),
};
